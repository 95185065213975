import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import useSiteSettings from '../../../hooks/useSiteSettings'
import { useSanityRoleBasedContent } from '../../../lib/sanity/hooks/useSanityRoleBasedContent'
import { pageSectionStylesFor } from '../../sanity-page/styles'
import BodyPortableText from '../../sanity-page/BodyPortableText'
import {
  Maybe,
  SanityBasicTextSection,
  SanityBlock,
  SanityPageSectionStyles,
  SanityRoleBasedContent,
} from '../../../graphql/gatsby'

const StyledContainer = styled.div<Pick<SanityBasicTextSection, '_rawPageSectionStyles'>>`
  ${({ _rawPageSectionStyles }) => pageSectionStylesFor(_rawPageSectionStyles)};
`

export interface DataSheetProps {
  _rawBody?: Maybe<SanityBlock>[]
  _rawPageSectionStyles?: Maybe<SanityPageSectionStyles>
  _rawRoleBasedContent?: Maybe<SanityRoleBasedContent>
}

const DataSheet: React.FC<DataSheetProps> = ({
  _rawBody: rawBody,
  _rawPageSectionStyles: rawPageSectionStyles,
  _rawRoleBasedContent: roleBasedContent,
}) => {
  const { shouldDisplay } = useSanityRoleBasedContent({ roleBasedContent })
  if (!rawBody || !shouldDisplay) {
    return null
  }
  return (
    <StyledContainer _rawPageSectionStyles={rawPageSectionStyles}>
      <BodyPortableText blocks={rawBody} />
    </StyledContainer>
  )
}

export interface DataSheetDisclaimerProps {
  className?: string
}

const DataSheetDisclaimer: React.FC<DataSheetDisclaimerProps> = ({ className }) => {
  const { datasheetDisclaimer: entries } = useSiteSettings() || {}
  return (
    <div className={className}>
      {!!entries &&
        entries.map((datasheet, index) => {
          return datasheet && <DataSheet {...datasheet} key={index} />
        })}
    </div>
  )
}

const StyledDataSheetDisclaimer = styled(DataSheetDisclaimer)`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-flow: row;
`

export default StyledDataSheetDisclaimer

export const datasheetDisclaimer = graphql`
  fragment datasheetDisclaimerFragment on SanitySiteSettings {
    datasheetDisclaimer {
      _rawBody(resolveReferences: { maxDepth: 10 })
      _rawPageSectionStyles
      _rawRoleBasedContent
    }
  }
`
